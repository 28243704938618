import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { RootState } from '../../store/reducers/rootReducer';
import { logout } from '../../store/slices/userAuthSlice';
import ProductsDropdown from '../ProductsDropdown/ProductsDropdown';
import styles from './Header.module.css';

const Header: React.FC = () => {
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.userAuth.isAuthenticated
  );
  const isAdmin = useAppSelector(
    (state: RootState) => !!state.userAuth.user?.admin
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={styles.header}>
      <div></div>
      <button
        className={`${styles.menuToggle} ${isMenuOpen ? styles.open : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span className={styles.hamburger}></span>
      </button>
      <nav className={`${styles.nav} ${isMenuOpen ? styles.open : ''}`}>
        <ul>
          {isAdmin && (
            <li>
              <Link to="/admin" onClick={() => setIsMenuOpen(false)}>
                Admin
              </Link>
            </li>
          )}
          {!isAuthenticated && (
            <>
              <li>
                <Link to="/" onClick={() => setIsMenuOpen(false)}>
                  Home
                </Link>
              </li>
              <li>
                <ProductsDropdown />
              </li>
              <li>
                <Link to="/about" onClick={() => setIsMenuOpen(false)}>
                  About
                </Link>
              </li>
            </>
          )}
          {isAuthenticated ? (
            <>
              <li>
                <Link to="/dashboard" onClick={() => setIsMenuOpen(false)}>
                  Dashboard
                </Link>
              </li>
              <li>
                <Link to="/account-settings">Account</Link>
              </li>
              <li>
                <Link to="/logout" onClick={handleLogout}>
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login" onClick={() => setIsMenuOpen(false)}>
                  Login
                </Link>
              </li>
              <li>
                <Link to="/signup" onClick={() => setIsMenuOpen(false)}>
                  Signup
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
