import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import {
  inviteUser,
  acceptInvitation,
  rejectInvitation,
} from '../../store/slices/organizationSlice';
import { post } from '../../api/apiUtils';
import styles from './OrganizationManagement.module.css';
import { ApiError } from '../../api/errorHandling';
import { PricingTable } from '../PricingTable/PricingTable';

interface Invite {
  id: string;
  organizationName: string;
}

const OrganizationManagement: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userAuth.user);
  const [inviteEmail, setInviteEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  // const organization = useSelector((state: RootState) => state.organization);
  const [pendingInvites, setPendingInvites] = useState<Invite[]>([]);

  useEffect(() => {
    fetchPendingInvites();
  }, []);

  const fetchPendingInvites = async () => {
    try {
      const response = await post<{ invites: Invite[] }>('/invites', {});
      setPendingInvites(response.invites);
    } catch (err) {
      setError('Failed to fetch pending invites');
    }
  };

  const handleInviteUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    try {
      await post<{}>('/users/invite-to-organization', {
        inviteEmail,
        inviteOrganization: user?.organizationId,
      });
      dispatch(inviteUser(inviteEmail));
      setSuccess(`Invitation sent to ${inviteEmail}`);
      setInviteEmail('');
    } catch (err) {
      console.log(err);
      if (
        err instanceof ApiError &&
        err.fieldErrors &&
        err.fieldErrors.invite
      ) {
        setError(err.fieldErrors.invite[0]);
      } else {
        setError('Failed to send invitation');
      }
    }
  };

  const handleInvitationAction = async (
    inviteId: string,
    action: 'accept' | 'reject'
  ) => {
    try {
      await post<{}>(`/invites/${inviteId}/action`, { action });
      if (action === 'accept') {
        dispatch(acceptInvitation(inviteId));
        setSuccess('Invitation accepted successfully');
      } else {
        dispatch(rejectInvitation(inviteId));
        setSuccess('Invitation rejected successfully');
      }
      fetchPendingInvites(); // Refresh the list of pending invites
    } catch (err) {
      setError(`Failed to ${action} invitation`);
    }
  };

  return (
    <div className={styles.organizationManagement}>
      <h1 className={styles.sectionTitle}>Organization Management</h1>

      {error && <div className={styles.error}>{error}</div>}
      {success && <div className={styles.success}>{success}</div>}

      <section className={styles.section}>
        <PricingTable />
      </section>

      <section className={styles.section}>
        <h3 className={styles.subsectionTitle}>Invite User</h3>
        <form onSubmit={handleInviteUser} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="inviteEmail" className={styles.label}>
              Email
            </label>
            <input
              type="email"
              id="inviteEmail"
              value={inviteEmail}
              onChange={e => setInviteEmail(e.target.value)}
              className={styles.input}
              required
            />
          </div>
          <button type="submit" className={styles.button}>
            Send Invitation
          </button>
        </form>
      </section>

      <section className={styles.section}>
        <h3 className={styles.subsectionTitle}>Pending Invitations</h3>
        {pendingInvites.length > 0 ? (
          <ul className={styles.inviteList}>
            {pendingInvites.map(invite => (
              <li key={invite.id} className={styles.inviteItem}>
                <span>{invite.organizationName}</span>
                <div className={styles.buttonGroup}>
                  <button
                    onClick={() => handleInvitationAction(invite.id, 'accept')}
                    className={`${styles.button} ${styles.acceptButton}`}
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => handleInvitationAction(invite.id, 'reject')}
                    className={`${styles.button} ${styles.rejectButton}`}
                  >
                    Reject
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No pending invitations</p>
        )}
      </section>
    </div>
  );
};

export default OrganizationManagement;
